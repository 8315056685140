import $api, { getCancelToken } from '..'
import { urlAgentGroups } from '../../environment/urls'

export const getFilteredAgentNumbersRequest = async ({
  page = 1,
  pageSize = 20,
  sortBy,
  descending = true,
  entityTypes = [],
  fields = [],
  companies = [],
  search = '',
  deleted = false,
  monthAdded,
} = {}) =>
  await $api.post(
    `${urlAgentGroups()}agents`,
    {
      page,
      pageSize,
      sortBy,
      descending,
      entityTypes,
      insuranceFields: fields,
      companies,
      search,
      deleted,
      monthAdded,
    },
    getCancelToken('getAgentNumbersRequest')
  )

export const exportAgentNumbersGroupsRequest = async () =>
  await $api
    .get(
      `${urlAgentGroups()}export-groups`,
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      },
      getCancelToken('exportAgentNumbersGroupsRequest')
    )
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'Agent Numbers Groups.xlsx'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Export Agent Numbers Groups from the backend.', response)
    })

export const importAgentNumbersGroupsRequest = async file => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('fileName', 'AgentNumbersGroups')
  return await $api.post(`${urlAgentGroups()}import-agent-groups-relations`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
