import { useState, useEffect, useCallback } from 'react'
import { lowercaseObjectKeys } from '../utils/objectUtils'

export default function useApiRequest(
  request,
  { requestParams, loadingOnInit = true, dataInit, logError, runOnInit = false } = {}
) {
  const [startOnInit, setStartOnInit] = useState(runOnInit)
  const [loading, setLoading] = useState(loadingOnInit)
  const [data, setData] = useState(dataInit)
  const [error, setError] = useState(null)

  const fetch = useCallback(
    async ({ silentLoading = false, params } = {}) => {
      try {
        setStartOnInit(false)
        setError(null)
        if (!silentLoading) {
          setLoading(true)
          setData(dataInit)
        }
        const resp = await request(params ?? requestParams)
        const { data = null } = resp ?? {}
        data && setData(data.Data ? lowercaseObjectKeys(data.Data) : data.data)
        setLoading(false)
      } catch (error) {
        logError && console.log('fetch error: ', error.message)
        setError(error.message)
        setLoading(false)
      }
    },
    [request, logError, dataInit, requestParams]
  )

  const refetch = ({ silentLoading = false, params } = {}) => fetch({ silentLoading, params })

  useEffect(() => {
    startOnInit && fetch()
  }, [fetch, startOnInit])

  return { loading, data, error, fetch: refetch }
}
