import { useEffect } from 'react'
import { ErrorCode, useDropzone } from 'react-dropzone'
import Button from '../Common/Button/Button'
import Loader from '../Common/Loader/Loader'
import ModalWindow from '../ModalWindow/ModalWindow'
import './ModalWindowImport.scss'

const ModalWindowImport = ({
  closeModal,
  title,
  heading,
  dndPlaceholder,
  templateDownloadTitle,
  downloadTemplate,
  uploadBtnCaption,
  importBtnCaption,
  fileTypes,
  maxFiles = 1,
  errors,
  setErrors = () => {},
  readFiles,
  files,
  deleteFiles,
  readSuccess = false,
  setReadSucces = () => {},
  submit,
  submitSuccess,
  submitLoading,
}) => {
  const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: fileTypes,
    maxFiles: maxFiles,
    onDropAccepted: readFiles,
    onFileDialogOpen: () => setErrors(null),
    onDrop: () => setErrors(null),
  })

  const submitHandler = () => {
    submit()
  }

  useEffect(() => {
    if (fileRejections.length > 0) {
      const errorCode = fileRejections[0].errors[0].code
      let error = null
      switch (errorCode) {
        case ErrorCode.FileInvalidType:
          error = 'Invalid File Type'
          break
        case ErrorCode.TooManyFiles:
          error = 'Multiple files upload is not allowed'
          break
        default:
          break
      }
      setErrors(error)
    }
  }, [fileRejections, setErrors])

  useEffect(() => {
    return () => {
      setErrors(null)
      setReadSucces(false)
    }
  }, [setErrors, setReadSucces])

  useEffect(() => {
    if (submitSuccess && !submitLoading) closeModal()
  }, [submitSuccess, submitLoading, closeModal])

  return (
    <ModalWindow closeModal={closeModal} closeOnOutsideClick={false} loadingOverlay={submitLoading}>
      <div className='modal-import-wrapper'>
        <div className='modal-import-title'>{title}</div>
        <div className='modal-import-content'>
          <div className='modal-import-content-heading'>{heading}</div>

          {!errors && readSuccess && files.length > 0 ? (
            <div className='modal-import-content-file-wrapper'>
              {files.map(file => (
                <div className='modal-import-content-file' key={file.path}>
                  {submitLoading && (
                    <div className='modal-import-content-loader-overlay'>
                      <Loader />
                    </div>
                  )}
                  <div className='modal-import-content-file-icon'>
                    <img src='./assets/report-icon/Text.png' alt='file' />
                  </div>
                  <div className='modal-import-content-file-name'>{file.path}</div>
                  <div className='modal-import-content-file-close' onClick={() => deleteFiles(file)}>
                    <img src='./assets/clear-icon-sm/Square.png' alt='close' />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className='modal-import-content-drop-zone'>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} multiple={maxFiles === 1 ? false : true} />
                <p>{dndPlaceholder}</p>
                <div onClick={open} className='import-button'>
                  {uploadBtnCaption}
                </div>
              </div>
              {errors && (
                <div className='errors' style={{ color: 'red' }}>
                  {errors}
                </div>
              )}
            </div>
          )}

          <div className='modal-import-content-template'>
            <div className='template-ico'>
              <img src='./assets/download-icon/Download.png' alt='dwnld' />
            </div>
            <div className='template-title' onClick={downloadTemplate}>
              {templateDownloadTitle}
            </div>
          </div>
        </div>
        <div className='modal-import-buttons'>
          <Button
            caption={importBtnCaption}
            onClick={submitHandler}
            disabled={errors || !readSuccess || !files.length > 0 || submitLoading}
          />
        </div>
      </div>
    </ModalWindow>
  )
}

export default ModalWindowImport
