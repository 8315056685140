import { useTranslation } from 'react-i18next'
import ModalWindowImport from '../ModalWindowImport/ModalWindowImport'
import { useState } from 'react'
import {
  exportAgentNumbersGroupsRequest,
  importAgentNumbersGroupsRequest,
} from '../../http/requests/agentGroupsRequests'
import useApiRequest from '../../hooks/useApiRequest'

const ImportAgentNumbersGroupModal = ({ closeModal, onImportSuccess = () => {} }) => {
  const { t } = useTranslation('profile')
  const [importLoading, setImportLoading] = useState(false)
  const [importSuccess, setImportSuccess] = useState(false)
  const [importError, setImportError] = useState(null)
  const [importFiles, setImportFiles] = useState([])
  const [fileReadSuccess, setFileReadSuccess] = useState(false)
  const { fetch: exportGroups, loading: exportGroupsLoading } = useApiRequest(exportAgentNumbersGroupsRequest, {
    loadingOnInit: false,
  })
  const readFile = async files => {
    setFileReadSuccess(false)
    const file = files[0]
    try {
      if (!file) {
        setImportError('File can not be read. Check contents')
      } else {
        setFileReadSuccess(true)
        setImportFiles([file])
      }
    } catch (error) {
      setImportError('File can not be read. Check contents')
    }
  }

  const deleteFile = file => {
    setImportFiles(importFiles.filter(itm => itm.path !== file.path))
  }

  const submitImportedData = async () => {
    try {
      setImportLoading(true)
      const {
        data: { data: result, errorMessage = 'Error' },
      } = await importAgentNumbersGroupsRequest(importFiles[0])
      if (!result) {
        setImportError(errorMessage)
        setImportFiles([])
      } else {
        setImportSuccess(true)
        onImportSuccess()
      }
      setImportLoading(false)
    } catch (error) {
      setImportError('Error')
      setImportLoading(false)
    }
  }

  const downloadTemplate = async () => {
    try {
      setImportLoading(true)
      setImportError(null)
      await exportGroups()
      setImportLoading(false)
    } catch (error) {
      setImportLoading(false)
    }
  }

  return (
    <ModalWindowImport
      closeModal={() => !(importLoading || exportGroupsLoading) && closeModal()}
      title={t('addGroupModal.importGroups')}
      heading={t('addGroupModal.importGroupsSubtitle')}
      dndPlaceholder={t('addGroupModal.dndPlaceholder')}
      templateDownloadTitle={t('addGroupModal.templateDownloadTitle')}
      uploadBtnCaption={t('addGroupModal.uploadBtn')}
      importBtnCaption={t('addGroupModal.importBtn')}
      fileTypes={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
      errors={importError}
      setErrors={setImportError}
      readFiles={readFile}
      readSuccess={fileReadSuccess}
      setReadSucces={setFileReadSuccess}
      files={importFiles}
      deleteFiles={deleteFile}
      submit={submitImportedData}
      submitSuccess={importSuccess}
      submitLoading={importLoading || exportGroupsLoading}
      downloadTemplate={downloadTemplate}
    />
  )
}

export default ImportAgentNumbersGroupModal
